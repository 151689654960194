<template>
  <div>
    <v-card-text>
      <v-card flat>
        <v-card-title class="tituloForm">
          Processo
        </v-card-title>
        <v-card-text>
          <v-text-field
              v-model="processoSelecionado.nome"
              dense
              label="Nome *"
              name="processoNome"
              maxlength="100"
              outlined
              validate-on-blur
              :rules="[campoObrigatorioRule, tamanhoMaximoRule(100)]"
          />
          <v-row dense justify="space-between">
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="processoSelecionado.ano"
                  v-mask="'####'"
                  :counter="4"
                  dense
                  label="Ano *"
                  name="processoAno"
                  outlined
                  validate-on-blur
                  :rules="[campoObrigatorioRule, tamanhoRule(4)]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="processoSelecionado.periodo"
                  v-mask="'#'"
                  :counter="1"
                  dense
                  label="Sequência *"
                  name="processoSequencia"
                  outlined
                  validate-on-blur
                  :rules="[campoObrigatorioRule, tamanhoRule(1)]"
              />
            </v-col>
          </v-row>
          <v-autocomplete
              v-model="processoSelecionado.tipoProcesso"
              :items="tiposProcessos"
              dense
              item-value="codigo"
              label="Selecione um Tipo de Processo *"
              name="processoTipoProcesso"
              outlined
              clearable
              return-object
              validate-on-blur
              :rules="[campoObrigatorioRule(processoSelecionado.tipoProcesso.codigo)]"
          >
            <template v-slot:selection="data">
              <v-sheet class="mx-1 my-1 pa-1" color="secondary" rounded
              ><span class="mx-2 d-flex"
              >{{ data.item.descricao }} - {{ data.item.codigo }}</span
              >
              </v-sheet>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                    v-html="data.item.descricao + ' - ' + data.item.codigo"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea
              v-model="
              processoSelecionado.informacaoComplementarProcesso
                .informacaoComplementar
            "
              dense
              clearable
              :counter="5000"
              maxlength="5000"
              label="Informações Complementares"
              name="processoInformacaoComplementar"
              outlined
          ></v-textarea>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title class="tituloForm">
          Especificações
        </v-card-title>
        <v-card-text>
          <v-select
              v-model="processoSelecionado.especificacoes"
              :items="especificacoesProcesso"
              item-text="descricao"
              label="Especificações"
              multiple
              dense
              outlined
              return-object
              small-chips
              single-line
          ></v-select>

          <v-text-field
              v-if="
              processoSelecionado.especificacoes.find(
                especificacao => especificacao.codigo === 'PNE'
              )
            "
              v-model="processoSelecionado.prefixoNumeroEnem"
              dense
              label="Prefixo do Número Enem que será aceito nesse processo *"
              placeholder="Ex. 11"
              name="processoPrefixoNumeroEnem"
              hint="Se for necessário mais de um valor separe por vírgula"
              outlined
              validate-on-blur
              :rules="[campoObrigatorioRule]"
          />
          <v-text-field
              v-if="
              processoSelecionado.especificacoes.find(
                especificacao => especificacao.codigo === 'UO'
              )
            "
              v-model="processoSelecionado.anoIngressoUniversidadeOrigem"
              dense
              label="Ano de ingresso da universidade de origem *"
              name="processoPrefixoNumeroEnem"
              hint="Se for necessário mais de um valor separe por vírgula"
              outlined
              validate-on-blur
              :rules="[campoObrigatorioRule]"
          />
          <v-textarea
              v-if="processoSelecionado.especificacoes.find(
                  especificacao => especificacao.codigo === 'EDOC'
              )"
              v-model="processoSelecionado.instrucoesEnvioDocumentos"
              :rules="[tamanhoMaximoRule(2000)]"
              counter="2000"
              outlined
              validate-on-blur
              label="Intruções para o envio de documentos (Este texto será exibido para o candidato)"
              placeholder="Digite o texto aqui..."
          />
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title class="tituloForm">
          Página de Divulgação
        </v-card-title>
        <v-card-text>
          <quill-editor
              :content="processoSelecionado.processoDivulgacao.texto"
              @change="onEditorChange($event)"
              :options="quillOptions"
              ref="myQuill"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import rules from "@/commons/rules";
import tipoProcessoService from "@/services/tipoProcesso.service";
import especificacaoProcessoService from "@/services/especificacaoProcesso.service";

export default {
  name: "ProcessoForm",

  props: {
    value: {type: Object, required: true}
  },

  data() {
    return {
      processoSelecionado: {
        tipoProcesso: {},
        especificacoes: [],
        informacaoComplementarProcesso: {},
        processoDivulgacao: {}
      },
      tiposProcessos: [],
      especificacoesProcesso: [],
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote"],

              [{header: 1}, {header: 2}], // custom button values
              [{list: "ordered"}, {list: "bullet"}],
              [{script: "sub"}, {script: "super"}], // superscript/subscript
              [{indent: "-1"}, {indent: "+1"}], // outdent/indent
              [{direction: "rtl"}], // text direction

              [{size: ["small", false, "large", "huge"]}], // custom dropdown
              [{header: [1, 2, 3, 4, 5, 6, false]}],

              [{color: []}, {background: []}], // dropdown with defaults from theme
              [{font: []}],
              [{align: []}],

              ["link", "video"]
            ]
          }
        },
        placeholder: "Insira o texto..."
      }
    };
  },

  async created() {
    let loader = this.$loading.show();

    this.processoSelecionado = {
      ...this.processoSelecionado,
      ...this.value
    };

    await tipoProcessoService
        .recuperarTiposProcesso()
        .then(response => {
          this.tiposProcessos = response.data;
        })
        .catch(() => {
        });

    await especificacaoProcessoService
        .recuperarEspecificacoesProcesso()
        .then(response => {
          this.especificacoesProcesso = response.data;
        })
        .catch(() => {
        });

    loader.hide();
  },

  watch: {
    processoSelecionado: {
      handler() {
        if (!this.processoSelecionado.tipoProcesso) {
          this.processoSelecionado.tipoProcesso = {};
        }

        if (!this.processoSelecionado.informacaoComplementarProcesso) {
          this.processoSelecionado.informacaoComplementarProcesso = {};
        }

        this.$emit("input", this.processoSelecionado);
      },
      deep: true
    }
  },

  methods: {
    ...rules,

    onEditorChange({html}) {
      this.processoSelecionado.processoDivulgacao.texto = html;
    }
  }
};
</script>
